import { tns } from 'tiny-slider'; // https://github.com/ganlanyuan/tiny-slider

// TODO. Polish the service and make it project agnostic.

export default class Slider {
	constructor(sliderSelector) {
		this.sliderNode = document.querySelector(sliderSelector);
		this.sliderWrapperNode = this.sliderNode?.parentNode;
		this.sliderType = null;
		this.sliderConfig = null;
		this.init();
	}

	getSliderConfig(type) {
		if (!this.sliderWrapperNode || !type) {
			return;
		}

		const config = {
			container: this.sliderNode,
			speed: 500,
			nav: false,
			autoplayButtonOutput: false,
		};

		switch(type) {
			case 'reviews': {
				return Object.assign(config, {
					prevButton: this.sliderWrapperNode.querySelector('.slider-arrow-left'),
					nextButton: this.sliderWrapperNode.querySelector('.slider-arrow-right'),
					loop: false,
					center: true,
				});
			}
			case 'facility-images': {
				return Object.assign(config, {
					controls: false,
					nav: true,
					navContainer: '.gallery-dots',
					loop: false,
					center: true,
				});
			}
			case 'convenience-features': {
				return Object.assign(config, {
					items: 2,
					loop: false,
					prevButton: this.sliderWrapperNode.querySelector('.slider-arrow-left'),
					nextButton: this.sliderWrapperNode.querySelector('.slider-arrow-right'),
					responsive: {
						481: {
							items: 3,
						},
						1281: {
							disable: true
						}
					}
				});
			}
			default: {
				return config;
			}
		}
	}

	init() {
		if (!this.sliderWrapperNode || !this.sliderNode) {
			return;
		}

		this.sliderType = this.sliderNode.getAttribute('data-slider');
		if (!this.sliderType) {
			console.error('sliderType not found');
			return;
		}

		this.sliderConfig = this.getSliderConfig(this.sliderType);
		if (!this.sliderConfig) {
			console.error('sliderConfig not found');
			return;
		}

		this.slider = tns(this.sliderConfig);
	}
}