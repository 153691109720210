import Map from './services/Map.js';
import Slider from './services/Slider.js';
import Overlay from './services/Overlay.js';
import FilterUnits from './services/FilterUnits.js';
import Contact from './services/Contact.js';

document.addEventListener('DOMContentLoaded', () => {
	const overlay = new Overlay();

	const findUsMap = new Map('[data-map="find-us"]');
	const reviewsSlider = new Slider('[data-slider="reviews"]');
	const featuresSlider = new Slider('[data-slider="convenience-features"]');

	const facilitySlider = new Slider('[data-slider="facility-images"]');
	const singleFacilityMap = new Map('[data-map="single-facility"]');

	const filterUnits = new FilterUnits('[data-js="facility-rates-node"]');

	const formContact = new Contact(
		'[data-form="contact"]',
		[
			'name',
			'phone',
			'email',
			'message',
		]
	);

	const formChangeAddress = new Contact(
		'[data-form="change-address"]',
		[
			'rental_unit_number',
			'name',
			'email',
			'day_time_phone',
			'effective_date',
			'new_mailing_address',
			'city_state_zip',
		]
	);

	const formMoveOut = new Contact(
		'[data-form="move-out"]',
		[
			'move_out_date',
			'rental_unit_number',
			'tenant_name',
			'email',
			'phone',
			'name',
			'address',
			'city_state_zip',
		]
	);

	// Toggle Mobile Menu
	(() => {
		const navOpenBtn = document.querySelector('[data-js="nav-open"]');
		const navCloseBtn = document.querySelector('[data-js="nav-close"]');
		const navMenu = document.querySelector('[data-js="nav-menu"]');

		navOpenBtn?.addEventListener('click', (e) => {
			console.info('open nav')
			navMenu?.classList.add('active');
			overlay.show();
			overlay.addListenerOnClose(() => {
				navMenu?.classList.remove('active');
			})
		});

		navCloseBtn?.addEventListener('click', (e) => {
			console.info('close nav')
			navMenu?.classList.remove('active');
			overlay.hide();
		});
	})();

	// Toggle Mobile Subitems
	(() => {
		const navMenu = document.querySelector('[data-js="nav-menu"]');

		if (!navMenu) {
			return;
		}

		navMenu.addEventListener('click', (e) => {
			console.info('toggle subitems')
			e.stopImmediatePropagation();

			const toggleMobileSubitems = e.target.closest('[data-js="toggle-subitems"]');

			if (!toggleMobileSubitems) {
				return;
			}

			toggleMobileSubitems.classList.toggle('active');
		});
	})();

	// Toggle Video Preview
	(() => {
		const loadVideoBtn = document.querySelector('[data-js="load-video"]');
		const videoFrame = document.querySelector('[data-load]');

		if (!loadVideoBtn || !videoFrame) {
			return;
		}

		loadVideoBtn.addEventListener('click', (e) => {
			if (!e.target.closest('[data-js="load-video"]')) {
				return;
			}

			videoFrame.setAttribute(
				'src',
				videoFrame.getAttribute('data-load')
			);

			loadVideoBtn.style.display = 'none';
		});
	})();

	// Single Facility - Switch Map / Gallery
	(() => {
		const wrapperGallery = document.querySelector('.wrapper-gallery[data-showing]');
		const viewControlBtns = document.querySelectorAll('.wrapper-gallery [data-show]');

		if (!wrapperGallery || viewControlBtns.length <= 0) {
			return;
		}

		wrapperGallery.addEventListener('click', (e) => {
			const viewControlBtn = e.target.closest('[data-show]');

			if (!viewControlBtn) {
				return;
			} 

			wrapperGallery.setAttribute(
				'data-showing', 
				viewControlBtn.getAttribute('data-show')
			);
		});
	})();

	// Toggle Storage FAQs
	(() => {
		const storageFaqs = document.querySelector('.storage-faqs');
		if (!storageFaqs) {
			return;
		}

		storageFaqs.addEventListener('click', (e) => {
			if (!e.target.classList.contains('question')) {
				return;
			}

			e.target.classList.toggle('active');
		});
	})();

	// Toggle Facility Tabs
	(() => {
		const facilityTabBtns = document.querySelectorAll('[data-js="facility-tabs"] .facility-tab-btn');

		if (facilityTabBtns.length <= 0) {
			return;
		}

		facilityTabBtns.forEach(btn => {
			btn.addEventListener('click', (e) => {
				facilityTabBtns.forEach(btn => btn.classList.remove('active'));
	
				e.target.classList.add('active');
			});
		});
	})();
}, true);